@import url('https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/nanumsquare.css');

html,
body,
#root {
  font-family: 'NanumSquare', 'sans-serif';
  margin: 0;
  padding: 0;
}

button,
a,
span,
div,
li,
input,
textarea,
label,
header,
footer,
section,
article,
h1,
h2,
h3,
h4,
h5,
h6,
p,
strong,
ul {
  font-family: 'NanumSquare', 'sans-serif';
}

a {
  text-decoration: none;
  color: #000;
}

button,
a {
  cursor: pointer;
}

li {
  list-style: none;
}
.swal-overlay {
  background-color: #c0c0c0c0;
}
.swal-text{
  text-align: center;
  color:#707070;
}
pre {
  font-family: 'NanumSquare', 'sans-serif';
  margin-top: 0;
  white-space: pre-wrap;
}
.swal-button {  
  padding: 9px 27px;
  border-radius: 3px;

  font-size: 14px;
  border: none;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}

